import React, { useEffect, useState, useMemo } from 'react';
import { shape, string } from 'prop-types';

import Box from '@material-ui/core/Box';

import { useAppContext } from '../../../../context/AppProvider';
import { DraftPicksPlayerListItem } from '../../../../components/Draft/DraftPicks/Item/DraftPicksPlayerListItem';
import Text from '../../../../components/Common/Text';

import s from './DraftPicksPlayerListContainer.module.scss';
import { fillRosterSlots } from '../../../../utils/players';

export const DraftPicksPlayerListContainer = ({ draft, userId }) => {
  const [{ user }] = useAppContext();
  const [filledRoster, setFilledRoster] = useState([]);

  const { roster } = draft?.contest?.rules;
  
  const getTitle = () => {
    return user.attributes.sub === userId ? 'My Picks' : draft?.order.find((fr) => fr.userId === userId)?.franchiseName || 'Unknown Franchise';
  };

  // Create contest slots based on roster rules
  const contestSlots = roster?.positions.map((slot) => Array.from(new Array(slot.starters)).map(() => slot.position)).flat();
  const sortOrder = ['QB', 'RB', 'WR', 'TE', 'FLEX', 'BN'];
  const positions = contestSlots.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));

  // Initialize roster slots
  const rosterSlots = positions.map((position) => ({
    fantasyPosition: '',
    fullName: '',
    photoUrl: '',
    rosterSlot: position,
    team: '',
    byeWeek: '',
  }));

  const flexPositions = roster?.positions.filter((pos) => pos.flex === 1).map((p) => p.position);
  
  // Get drafted players for the user
  const draftedPlayers = useMemo(() => 
    draft?.summary
      ?.filter((item) => item.franchise.userId === userId)
      .map((item) => ({ ...item.player, canFlex: flexPositions.includes(item.player.fantasyPosition) })) || [],
  [draft, userId, flexPositions]);

  useEffect(() => {
    const filledRosterByPosition = fillRosterSlots(rosterSlots, draftedPlayers);
    setFilledRoster(filledRosterByPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.summary, userId]);

  return (
    <Box className={s.root}>
      <Text bold variant="body1">
        {getTitle()}
      </Text>
      {filledRoster.map((slot) => (
        <DraftPicksPlayerListItem
          key={slot.playerId}
          draftid={draft.draftId}
          rosterslot={slot.rosterSlot}
          fullname={slot.fullName}
          photourl={slot.photoUrl}
          position={slot.fantasyPosition}
          team={slot.team}
          byeweek={slot.byeWeek}
        />
      ))}
    </Box>
  );
};

DraftPicksPlayerListContainer.propTypes = {
  draft: shape({}),
  userId: string.isRequired,
};
